var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.value, width: "960" },
      on: {
        input: function ($event) {
          return _vm.$emit("input", false)
        },
      },
    },
    [
      _vm.text == "tos"
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "headline" },
                [
                  _vm._v(" Terms and Conditions "),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", absolute: "", right: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("input", false)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-subtitle", [_vm._v("Last updated: July 07, 2020")]),
              _c("v-card-text", [
                _c("p", [
                  _vm._v(
                    "Please read these terms and conditions carefully before using Our Service."
                  ),
                ]),
                _c("h1", [_vm._v("Interpretation and Definitions")]),
                _c("h2", [_vm._v("Interpretation")]),
                _c("p", [
                  _vm._v(
                    " The words of which the initial letter is capitalized have meanings defined under the following conditions. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " The following definitions shall have the same meaning regardless of whether they appear in singular or in plural. "
                  ),
                ]),
                _c("h2", [_vm._v("Definitions")]),
                _c("p", [
                  _vm._v("For the purposes of these Terms and Conditions:"),
                ]),
                _c("ul", [
                  _c("li", [
                    _c("strong", [_vm._v("Affiliate")]),
                    _vm._v(
                      ' means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority. '
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Account")]),
                    _vm._v(
                      " means a unique account created for You to access our Service or parts of our Service. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Company")]),
                    _vm._v(
                      ' (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to ConSteel Solutions Ltd., Mester utca 87.. '
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Content")]),
                    _vm._v(
                      " refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Country")]),
                    _vm._v(" refers to: Hungary"),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Device")]),
                    _vm._v(
                      " means any device that can access the Service such as a computer, a cellphone or a digital tablet. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Service")]),
                    _vm._v(" refers to the Website."),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Terms and Conditions")]),
                    _vm._v(
                      ' (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service. '
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Third-party Social Media Service")]),
                    _vm._v(
                      " means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Website")]),
                    _vm._v(
                      " refers to Steelspace, accessible from steelspace.io"
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("You")]),
                    _vm._v(
                      " means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable. "
                    ),
                  ]),
                ]),
                _c("h1", [_vm._v("Acknowledgement")]),
                _c("p", [
                  _vm._v(
                    " These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service. "
                  ),
                ]),
                _c("h1", [_vm._v("User Accounts")]),
                _c("p", [
                  _vm._v(
                    " When You create an account with Us, You must provide Us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your account on Our Service. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under Your password, whether Your password is with Our Service or a Third-Party Social Media Service. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of Your account. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene. "
                  ),
                ]),
                _c("h1", [_vm._v("Content")]),
                _c("h2", [_vm._v("Your Right to Post Content")]),
                _c("p", [
                  _vm._v(
                    " Our Service allows You to post Content. You are responsible for the Content that You post to the Service, including its legality, reliability, and appropriateness. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " By posting Content to the Service, You grant Us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You retain any and all of Your rights to any Content You submit, post or display on or through the Service and You are responsible for protecting those rights. You agree that this license includes the right for Us to make Your Content available to other users of the Service, who may also use Your Content subject to these Terms. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " You represent and warrant that: (i) the Content is Yours (You own it) or You have the right to use it and grant Us the rights and license as provided in these Terms, and (ii) the posting of Your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person. "
                  ),
                ]),
                _c("h2", [_vm._v("Content Restrictions")]),
                _c("p", [
                  _vm._v(
                    " The Company is not responsible for the content of the Service's users. You expressly understand and agree that You are solely responsible for the Content and for all activity that occurs under your account, whether done so by You or any third person using Your account. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " You may not transmit any Content that is unlawful, offensive, upsetting, intended to disgust, threatening, libelous, defamatory, obscene or otherwise objectionable. Examples of such objectionable Content include, but are not limited to, the following: "
                  ),
                ]),
                _c("ul", [
                  _c("li", [
                    _vm._v("Unlawful or promoting unlawful activity."),
                  ]),
                  _c("li", [
                    _vm._v(
                      " Defamatory, discriminatory, or mean-spirited content, including references or commentary about religion, race, sexual orientation, gender, national/ethnic origin, or other targeted groups. "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      " Spam, machine – or randomly – generated, constituting unauthorized or unsolicited advertising, chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling. "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      " Containing or installing any viruses, worms, malware, trojan horses, or other content that is designed or intended to disrupt, damage, or limit the functioning of any software, hardware or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of a third person. "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      " Infringing on any proprietary rights of any party, including patent, trademark, trade secret, copyright, right of publicity or other rights. "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      " Impersonating any person or entity including the Company and its employees or representatives. "
                    ),
                  ]),
                  _c("li", [
                    _vm._v("Violating the privacy of any third person."),
                  ]),
                  _c("li", [_vm._v("False information and features.")]),
                ]),
                _c("p", [
                  _vm._v(
                    " The Company reserves the right, but not the obligation, to, in its sole discretion, determine whether or not any Content is appropriate and complies with this Terms, refuse or remove this Content. The Company further reserves the right to make formatting and edits and change the manner any Content. The Company can also limit or revoke the use of the Service if You post such objectionable Content. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " As the Company cannot control all content posted by users and/or third parties on the Service, you agree to use the Service at your own risk. You understand that by using the Service You may be exposed to content that You may find offensive, indecent, incorrect or objectionable, and You agree that under no circumstances will the Company be liable in any way for any content, including any errors or omissions in any content, or any loss or damage of any kind incurred as a result of your use of any content. "
                  ),
                ]),
                _c("h2", [_vm._v("Content Backups")]),
                _c("p", [
                  _vm._v(
                    " Although regular backups of Content are performed, the Company do not guarantee there will be no loss or corruption of data. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Corrupt or invalid backup points may be caused by, without limitation, Content that is corrupted prior to being backed up or that changes during the time a backup is performed. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " The Company will provide support and attempt to troubleshoot any known or discovered issues that may affect the backups of Content. But You acknowledge that the Company has no liability related to the integrity of Content or the failure to successfully restore Content to a usable state. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " You agree to maintain a complete and accurate copy of any Content in a location independent of the Service. "
                  ),
                ]),
                _c("h1", [_vm._v("Copyright Policy")]),
                _c("h2", [_vm._v("Intellectual Property Infringement")]),
                _c("p", [
                  _vm._v(
                    " We respect the intellectual property rights of others. It is Our policy to respond to any claim that Content posted on the Service infringes a copyright or other intellectual property infringement of any person. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " If You are a copyright owner, or authorized on behalf of one, and You believe that the copyrighted work has been copied in a way that constitutes copyright infringement that is taking place through the Service, You must submit Your notice in writing to the attention of our copyright agent via email at info@consteelsoftware.com and include in Your notice a detailed description of the alleged infringement. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that any Content is infringing Your copyright. "
                  ),
                ]),
                _c("h2", [
                  _vm._v(
                    "DMCA Notice and DMCA Procedure for Copyright Infringement Claims"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail): "
                  ),
                ]),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      " An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright's interest. "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      " A description of the copyrighted work that You claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work. "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      " Identification of the URL or other specific location on the Service where the material that You claim is infringing is located. "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "Your address, telephone number, and email address."
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      " A statement by You that You have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law. "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      " A statement by You, made under penalty of perjury, that the above information in Your notice is accurate and that You are the copyright owner or authorized to act on the copyright owner's behalf. "
                    ),
                  ]),
                ]),
                _c("p", [
                  _vm._v(
                    "You can contact our copyright agent via email at info@consteelsoftware.com."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Upon receipt of a notification, the Company will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged content from the Service. "
                  ),
                ]),
                _c("h1", [_vm._v("Links to Other Websites")]),
                _c("p", [
                  _vm._v(
                    " Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit. "
                  ),
                ]),
                _c("h1", [_vm._v("Termination")]),
                _c("p", [
                  _vm._v(
                    " We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your Account, You may simply discontinue using the Service. "
                  ),
                ]),
                _c("h1", [_vm._v("Limitation of Liability")]),
                _c("p", [
                  _vm._v(
                    " Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law. "
                  ),
                ]),
                _c("h1", [_vm._v('"AS IS" and "AS AVAILABLE" Disclaimer')]),
                _c("p", [
                  _vm._v(
                    ' The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected. '
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law. "
                  ),
                ]),
                _c("h1", [_vm._v("Governing Law")]),
                _c("p", [
                  _vm._v(
                    " The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws. "
                  ),
                ]),
                _c("h1", [_vm._v("Disputes Resolution")]),
                _c("p", [
                  _vm._v(
                    " If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company. "
                  ),
                ]),
                _c("h1", [_vm._v("For European Union (EU) Users")]),
                _c("p", [
                  _vm._v(
                    " If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in. "
                  ),
                ]),
                _c("h1", [_vm._v("United States Legal Compliance")]),
                _c("p", [
                  _vm._v(
                    ' You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a "terrorist supporting" country, and (ii) You are not listed on any United States government list of prohibited or restricted parties. '
                  ),
                ]),
                _c("h1", [_vm._v("Severability and Waiver")]),
                _c("h2", [_vm._v("Severability")]),
                _c("p", [
                  _vm._v(
                    " If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect. "
                  ),
                ]),
                _c("h2", [_vm._v("Waiver")]),
                _c("p", [
                  _vm._v(
                    " Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach. "
                  ),
                ]),
                _c("h1", [_vm._v("Translation Interpretation")]),
                _c("p", [
                  _vm._v(
                    " These Terms and Conditions may have been translated if We have made them available to You on our Service. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "You agree that the original English text shall prevail in the case of a dispute."
                  ),
                ]),
                _c("h1", [_vm._v("Changes to These Terms and Conditions")]),
                _c("p", [
                  _vm._v(
                    " We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service. "
                  ),
                ]),
                _c("h1", [_vm._v("Contact Us")]),
                _c("p", [
                  _vm._v(
                    "If you have any questions about these Terms and Conditions, You can contact us:"
                  ),
                ]),
                _c("ul", [
                  _c("li", [_vm._v("By email: info@consteelsoftware.com")]),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.text == "pdpolicy"
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "headline" },
                [
                  _vm._v(" Privacy & Data Policy "),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", absolute: "", right: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("input", false)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-subtitle", [_vm._v("Last updated: July 07, 2020")]),
              _c("v-card-text", [
                _c("p", [
                  _vm._v(
                    " This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. "
                  ),
                ]),
                _c("h1", [_vm._v("Interpretation and Definitions")]),
                _c("h2", [_vm._v("Interpretation")]),
                _c("p", [
                  _vm._v(
                    " The words of which the initial letter is capitalized have meanings defined under the following conditions. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " The following definitions shall have the same meaning regardless of whether they appear in singular or in plural. "
                  ),
                ]),
                _c("h2", [_vm._v("Definitions")]),
                _c("p", [_vm._v("For the purposes of this Privacy Policy:")]),
                _c("ul", [
                  _c("li", [
                    _c("p", [
                      _c("strong", [_vm._v("You")]),
                      _vm._v(
                        " means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable. "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject or as the User as you are the individual using the Service. "
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("p", [
                      _c("strong", [_vm._v("Company")]),
                      _vm._v(
                        ' (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to ConSteel Solutions Ltd. , Mester utca 87.. '
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "For the purpose of the GDPR, the Company is the Data Controller."
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Affiliate")]),
                    _vm._v(
                      ' means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority. '
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Account")]),
                    _vm._v(
                      " means a unique account created for You to access our Service or parts of our Service. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Website")]),
                    _vm._v(
                      " refers to Steelspace, accessible from https://steelspace.io/ "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Service")]),
                    _vm._v(" refers to the Website."),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Country")]),
                    _vm._v(" refers to: Hungary"),
                  ]),
                  _c("li", [
                    _c("p", [
                      _c("strong", [_vm._v("Service Provider")]),
                      _vm._v(
                        " means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used. "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "For the purpose of the GDPR, Service Providers are considered Data Processors."
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Third-party Social Media Service")]),
                    _vm._v(
                      " refers to any website or any social network website through which a User can log in or create an account to use the Service. "
                    ),
                  ]),
                  _c("li", [
                    _c("p", [
                      _c("strong", [_vm._v("Personal Data")]),
                      _vm._v(
                        " is any information that relates to an identified or identifiable individual. "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " For the purposes for GDPR, Personal Data means any information relating to You such as a name, an identification number, location data, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity. "
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Cookies")]),
                    _vm._v(
                      " are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Device")]),
                    _vm._v(
                      " means any device that can access the Service such as a computer, a cellphone or a digital tablet. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Usage Data")]),
                    _vm._v(
                      " refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit). "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Data Controller")]),
                    _vm._v(
                      ", for the purposes of the GDPR (General Data Protection Regulation), refers to the Company as the legal person which alone or jointly with others determines the purposes and means of the processing of Personal Data. "
                    ),
                  ]),
                ]),
                _c("h1", [_vm._v("Collecting and Using Your Personal Data")]),
                _c("h2", [_vm._v("Types of Data Collected")]),
                _c("h3", [_vm._v("Personal Data")]),
                _c("p", [
                  _vm._v(
                    " While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to: "
                  ),
                ]),
                _c("ul", [
                  _c("li", [_vm._v("Email address")]),
                  _c("li", [_vm._v("First name and last name")]),
                  _c("li", [_vm._v("Usage Data")]),
                ]),
                _c("h3", [_vm._v("Usage Data")]),
                _c("p", [
                  _vm._v(
                    "Usage Data is collected automatically when using the Service."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device. "
                  ),
                ]),
                _c("h3", [_vm._v("Tracking Technologies and Cookies")]),
                _c("p", [
                  _vm._v(
                    " We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    ' Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser. Learn more about cookies in the '
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.privacypolicies.com/blog/cookies/",
                      },
                    },
                    [_vm._v('"What Are Cookies"')]
                  ),
                  _vm._v(" article. "),
                ]),
                _c("p", [
                  _vm._v(
                    "We use both session and persistent Cookies for the purposes set out below:"
                  ),
                ]),
                _c("ul", [
                  _c("li", [
                    _c("p", [
                      _c("strong", [_vm._v("Necessary / Essential Cookies")]),
                    ]),
                    _c("p", [_vm._v("Type: Session Cookies")]),
                    _c("p", [_vm._v("Administered by: Us")]),
                    _c("p", [
                      _vm._v(
                        " Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services. "
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v("Cookies Policy / Notice Acceptance Cookies"),
                      ]),
                    ]),
                    _c("p", [_vm._v("Type: Persistent Cookies")]),
                    _c("p", [_vm._v("Administered by: Us")]),
                    _c("p", [
                      _vm._v(
                        " Purpose: These Cookies identify if users have accepted the use of cookies on the Website. "
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("p", [_c("strong", [_vm._v("Functionality Cookies")])]),
                    _c("p", [_vm._v("Type: Persistent Cookies")]),
                    _c("p", [_vm._v("Administered by: Us")]),
                    _c("p", [
                      _vm._v(
                        " Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website. "
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v("Tracking and Performance Cookies"),
                      ]),
                    ]),
                    _c("p", [_vm._v("Type: Persistent Cookies")]),
                    _c("p", [_vm._v("Administered by: Third-Parties")]),
                    _c("p", [
                      _vm._v(
                        " Purpose: These Cookies are used to track information about traffic to the Website and how users use the Website. The information gathered via these Cookies may directly or indirectly identify you as an individual visitor. This is because the information collected is typically linked to a pseudonymous identifier associated with the device you use to access the Website. We may also use these Cookies to test new advertisements, pages, features or new functionality of the Website to see how our users react to them. "
                      ),
                    ]),
                  ]),
                ]),
                _c("p", [
                  _vm._v(
                    " For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy. "
                  ),
                ]),
                _c("h2", [_vm._v("Use of Your Personal Data")]),
                _c("p", [
                  _vm._v(
                    "The Company may use Personal Data for the following purposes:"
                  ),
                ]),
                _c("ul", [
                  _c("li", [
                    _c("strong", [
                      _vm._v("To provide and maintain our Service"),
                    ]),
                    _vm._v(", including to monitor the usage of our Service. "),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("To manage Your Account:")]),
                    _vm._v(
                      " to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [
                      _vm._v("For the performance of a contract:"),
                    ]),
                    _vm._v(
                      " the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("To contact You:")]),
                    _vm._v(
                      " To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("To provide You")]),
                    _vm._v(
                      " with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("To manage Your requests:")]),
                    _vm._v(" To attend and manage Your requests to Us. "),
                  ]),
                ]),
                _c("p", [
                  _vm._v(
                    "We may share your personal information in the following situations:"
                  ),
                ]),
                _c("ul", [
                  _c("li", [
                    _c("strong", [_vm._v("With Service Providers:")]),
                    _vm._v(
                      " We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("For Business transfers:")]),
                    _vm._v(
                      " We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("With Affiliates:")]),
                    _vm._v(
                      " We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("With Business partners:")]),
                    _vm._v(
                      " We may share Your information with Our business partners to offer You certain products, services or promotions. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("With other users:")]),
                    _vm._v(
                      " when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile. "
                    ),
                  ]),
                ]),
                _c("h2", [_vm._v("Retention of Your Personal Data")]),
                _c("p", [
                  _vm._v(
                    " The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods. "
                  ),
                ]),
                _c("h2", [_vm._v("Transfer of Your Personal Data")]),
                _c("p", [
                  _vm._v(
                    " Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information. "
                  ),
                ]),
                _c("h2", [_vm._v("Disclosure of Your Personal Data")]),
                _c("h3", [_vm._v("Business Transactions")]),
                _c("p", [
                  _vm._v(
                    " If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy. "
                  ),
                ]),
                _c("h3", [_vm._v("Law enforcement")]),
                _c("p", [
                  _vm._v(
                    " Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency). "
                  ),
                ]),
                _c("h3", [_vm._v("Other legal requirements")]),
                _c("p", [
                  _vm._v(
                    " The Company may disclose Your Personal Data in the good faith belief that such action is necessary to: "
                  ),
                ]),
                _c("ul", [
                  _c("li", [_vm._v("Comply with a legal obligation")]),
                  _c("li", [
                    _vm._v(
                      "Protect and defend the rights or property of the Company"
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "Prevent or investigate possible wrongdoing in connection with the Service"
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "Protect the personal safety of Users of the Service or the public"
                    ),
                  ]),
                  _c("li", [_vm._v("Protect against legal liability")]),
                ]),
                _c("h2", [_vm._v("Security of Your Personal Data")]),
                _c("p", [
                  _vm._v(
                    " The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security. "
                  ),
                ]),
                _c("h1", [
                  _vm._v(
                    "Detailed Information on the Processing of Your Personal Data"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are obligated not to disclose or use it for any other purpose. "
                  ),
                ]),
                _c("h2", [_vm._v("Analytics")]),
                _c("p", [
                  _vm._v(
                    " We may use third-party Service providers to monitor and analyze the use of our Service. "
                  ),
                ]),
                _c("ul", [
                  _c("li", [
                    _c("p", [_c("strong", [_vm._v("Google Analytics")])]),
                    _c("p", [
                      _vm._v(
                        " Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network. "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google Analytics about visits activity. "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: "
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://policies.google.com/privacy?hl=en",
                          },
                        },
                        [_vm._v("https://policies.google.com/privacy?hl=en")]
                      ),
                    ]),
                  ]),
                ]),
                _c("h2", [_vm._v("Email Marketing")]),
                _c("p", [
                  _vm._v(
                    " We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and other information that may be of interest to You. You may opt-out of receiving any, or all, of these communications from Us by following the unsubscribe link or instructions provided in any email We send or by contacting Us. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "We may use Email Marketing Service Providers to manage and send emails to You."
                  ),
                ]),
                _c("h1", [_vm._v("GDPR Privacy")]),
                _c("h2", [
                  _vm._v("Legal Basis for Processing Personal Data under GDPR"),
                ]),
                _c("p", [
                  _vm._v(
                    "We may process Personal Data under the following conditions:"
                  ),
                ]),
                _c("ul", [
                  _c("li", [
                    _c("strong", [_vm._v("Consent:")]),
                    _vm._v(
                      " You have given Your consent for processing Personal Data for one or more specific purposes. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Performance of a contract:")]),
                    _vm._v(
                      " Provision of Personal Data is necessary for the performance of an agreement with You and/or for any pre-contractual obligations thereof. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Legal obligations:")]),
                    _vm._v(
                      " Processing Personal Data is necessary for compliance with a legal obligation to which the Company is subject. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Vital interests:")]),
                    _vm._v(
                      " Processing Personal Data is necessary in order to protect Your vital interests or of another natural person. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Public interests:")]),
                    _vm._v(
                      " Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Legitimate interests:")]),
                    _vm._v(
                      " Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the Company. "
                    ),
                  ]),
                ]),
                _c("p", [
                  _vm._v(
                    " In any case, the Company will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract. "
                  ),
                ]),
                _c("h2", [_vm._v("Your Rights under the GDPR")]),
                _c("p", [
                  _vm._v(
                    " The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " You have the right under this Privacy Policy, and by law if You are within the EU, to: "
                  ),
                ]),
                _c("ul", [
                  _c("li", [
                    _c("strong", [
                      _vm._v("Request access to Your Personal Data."),
                    ]),
                    _vm._v(
                      " The right to access, update or delete the information We have on You. Whenever made possible, you can access, update or request deletion of Your Personal Data directly within Your account settings section. If you are unable to perform these actions yourself, please contact Us to assist You. This also enables You to receive a copy of the Personal Data We hold about You. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [
                      _vm._v(
                        "Request correction of the Personal Data that We hold about You."
                      ),
                    ]),
                    _vm._v(
                      " You have the right to to have any incomplete or inaccurate information We hold about You corrected. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [
                      _vm._v("Object to processing of Your Personal Data."),
                    ]),
                    _vm._v(
                      " This right exists where We are relying on a legitimate interest as the legal basis for Our processing and there is something about Your particular situation, which makes You want to object to our processing of Your Personal Data on this ground. You also have the right to object where We are processing Your Personal Data for direct marketing purposes. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [
                      _vm._v("Request erasure of Your Personal Data."),
                    ]),
                    _vm._v(
                      " You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [
                      _vm._v("Request the transfer of Your Personal Data."),
                    ]),
                    _vm._v(
                      " We will provide to You, or to a third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information which You initially provided consent for Us to use or where We used the information to perform a contract with You. "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Withdraw Your consent.")]),
                    _vm._v(
                      " You have the right to withdraw Your consent on using your Personal Data. If You withdraw Your consent, We may not be able to provide You with access to certain specific functionalities of the Service. "
                    ),
                  ]),
                ]),
                _c("h2", [
                  _vm._v("Exercising of Your GDPR Data Protection Rights"),
                ]),
                _c("p", [
                  _vm._v(
                    " You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us. Please note that we may ask You to verify Your identity before responding to such requests. If You make a request, We will try our best to respond to You as soon as possible. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " You have the right to complain to a Data Protection Authority about Our collection and use of Your Personal Data. For more information, if You are in the European Economic Area (EEA), please contact Your local data protection authority in the EEA. "
                  ),
                ]),
                _c("h1", [_vm._v("Children's Privacy")]),
                _c("p", [
                  _vm._v(
                    " Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers. "
                  ),
                ]),
                _c("h1", [
                  _vm._v(
                    "Your California Privacy Rights (California's Shine the Light law)"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Under California Civil Code Section 1798 (California's Shine the Light law), California residents with an established business relationship with us can request information once a year about sharing their Personal Data with third parties for the third parties' direct marketing purposes. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " If you'd like to request more information under the California Shine the Light law, You can contact Us using the contact information provided below. "
                  ),
                ]),
                _c("h1", [
                  _vm._v(
                    " California Privacy Rights for Minor Users (California Business and Professions Code Section 22581) "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " California Business and Professions Code section 22581 allow California residents under the age of 18 who are registered users of online sites, services or applications to request and obtain removal of content or information they have publicly posted. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " To request removal of such data, and if you are a California resident, You can contact Us using the contact information provided below, and include the email address associated with Your account. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Be aware that Your request does not guarantee complete or comprehensive removal of content or information posted online and that the law may not permit or require removal in certain circumstances. "
                  ),
                ]),
                _c("h1", [_vm._v("Links to Other Websites")]),
                _c("p", [
                  _vm._v(
                    " Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services. "
                  ),
                ]),
                _c("h1", [_vm._v("Changes to this Privacy Policy")]),
                _c("p", [
                  _vm._v(
                    " We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    ' We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy. '
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page. "
                  ),
                ]),
                _c("h1", [_vm._v("Contact Us")]),
                _c("p", [
                  _vm._v(
                    "If you have any questions about this Privacy Policy, You can contact us:"
                  ),
                ]),
                _c("ul", [
                  _c("li", [_vm._v("By email: info@consteelsoftware.com")]),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }